<template>
    <div class="add">
        <el-dialog :title="'添加量表' + '(' + num + ')'" :visible.sync="dialogVisible" width="50%" :show-close="false">
            <div class="addGauge">
                <div class="searchBtn">
                    <el-input size="small" placeholder="请输入量表名称" clearable style="width: 150px"
                        v-model="searchInfo.name"></el-input>
                    <el-select size="small" width="150px" v-model="searchInfo.gaugeTypeId" placeholder="请选择量表类别">
                        <el-option v-for="item in gaugeTypeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="primary" size="small" @click="search">查询</el-button>
                </div>
                <div class="tableList">
                    <el-table :data="tableData" border style="width: 100%" size="small" height="500px"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column prop="name" label="量表名称" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="showName" label="显示名称" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="题数" prop="problemCount" width="80">
                        </el-table-column>
                        <el-table-column label="量表类别" prop="gaugeTypeName" width="150" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submit" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getGaugeType } from '@/api/gauge/gaugeIntroduction.js'
import { getGaugeList, addGauge } from '@/api/evaluationManagement/plan'
export default {
    data() {
        return {
            dialogVisible: false,
            searchInfo: {
                gaugeTypeId: null,
                name: null
            },
            num: 0,
            addGaugeIdList: [],
            gaugeTypeList: [],
            tableData: []
        }
    },
    methods: {
        openAdd() {
            this.getGaugesList();
            this.getGaugeTypeList();
            this.dialogVisible = true;
        },
        // 获取量表类别
        async getGaugeTypeList() {
            const res = await getGaugeType();
            console.log(res);
            if (res.code === 200) {
                this.gaugeTypeList = res.data;
                this.gaugeTypeList = [{ id: -1, name: '全部' }, ...this.gaugeTypeList]
            }
        },
        // 获取量表列表
        async getGaugesList() {
            const infoData = new FormData();
            infoData.append('planId',Number(sessionStorage.getItem('planId')));
            const res = await getGaugeList(infoData);
            console.log(res)
            if (res.code === 200) {
                this.tableData = res.data
            }
        },
        // 点击搜索
        async search() {
            let info = this.searchInfo;

            const infoData = new FormData();
            if (info.gaugeTypeId !== -1) {
                infoData.append('gaugeTypeId', info.gaugeTypeId);
            }
            infoData.append('name', info.name);
            infoData.append('planId',Number(sessionStorage.getItem('planId')));
            const res = await getGaugeList(infoData);
            console.log(res)
            if (res.code === 200) {
                this.tableData = res.data
            }
        },
        // 点击选择
        handleSelectionChange(val) {
            console.log(val);
            if (val) {
                this.num = val.length;
                this.addGaugeIdList = val;
            }
        },
        // 点击确定
        async submit() {
            let gaugeId = [];
            this.addGaugeIdList.forEach(item => {
                gaugeId.push({ gaugeId: item.id, planId: Number(sessionStorage.getItem('planId')) });
            })
            // console.log(gaugeId);
            const res = await addGauge(JSON.stringify(gaugeId));
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '添加成功！',
                    type: 'success'
                });
                this.dialogVisible = false;
                this.$emit('getDetail');
            } else {
                this.$message({
                    showClose: true,
                    message: '添加失败！',
                    type: 'error'
                });
            }

        }

    },
    created() {

    }

}
</script>

<style lang="scss" scoped>
.add {
    ::v-deep.el-dialog {
        height: 75%;
        overflow-y: scroll;
        // position: relative;
    }

    .addGauge {

        // .btn {
        //     margin-top: 20px;
        //     display: flex;

        //     .el-input,
        //     .el-select {
        //         margin-right: 30px;
        //         width: 150px;
        //     }

        //     .el-button {
        //         margin-left: 20px;
        //     }
        // }

        .tableList {
            margin-top: 20px;
        }
    }
}
</style>