// 页面配置
import service from '@/utils/request'
// 查看报告列表
export function getSetting(params) {
  return service({
    url: '/user/config',
    method: 'get',
    params
  });
}
// 获取页脚
export function getFooter(params) {
  return service({
    url: 'footer',
    method: 'get',
    params
  });
}