<template>
  <div class="Overview">
    <!-- 计划概况 -->
    <div class="tableForm">
      <el-form :model="form" size="small" :rules="rules" ref="form">
        <el-form-item>
          <div class="first">
            <div class="left">
              <span class="labelName left">测评计划名称</span>
              <el-input v-model="form.name" clearable @blur="changePlan" maxlength="25"></el-input>
            </div>
            <div class="right">
              <span class="labelName">创建信息</span>
              <span class="createInfo" v-if="planId"><span style="display:inline-block; min-width: 40px">{{ form.userName
              }}</span> 于
                <span v-if="form.createTime">
                  {{ form.createTime }}
                </span>创建</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="first">
            <div class="left">
              <span class="labelName left">测评计划时间</span>
              <div class="time">
                <el-date-picker v-model="form.startTime" type="date" placeholder="选择日期" format="yyyy - MM - dd"
                  @change="changePlan" value-format="yyyy-MM-dd 00:00:00">
                </el-date-picker>
                <span>至</span>
                <el-date-picker v-model="form.endTime" type="date" placeholder="选择日期" format="yyyy - MM - dd"
                  @change="changePlan" value-format="yyyy-MM-dd 23:59:59">
                </el-date-picker>
              </div>
              <!-- <el-date-picker unlink-panels  v-model="val" type="daterange" align="right"  range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @blur="changePlan"  format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd">
              </el-date-picker> -->
            </div>
            <div class="right">
              <span class="labelName">测评计划链接地址</span>
              <!-- <span>http://192.168.0.101:8080?id={{ planId }}</span> -->
              <span>{{ $store.state.downLoadUrl }}?id={{ planId }}&isScan={{ isCode }}&isRg={{ isRegister }}</span>
              <!-- <span>{{ $store.state.downLoadUrl }}?id={{ planId }}</span> -->

              <!-- <span></span> -->
              <!-- <el-input readonly value="http://192.168.0.101:8080"></el-input> -->
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="first">
            <div class="left1">
              <div><span class="labelName left">重复计划</span>
                <el-select size="small" v-model="form.repeatPlan" placeholder="请选择周期配置" @change="changePlan">
                  <el-option label="无周期" :value="1">
                  </el-option>
                  <el-option label="每周一次" :value="2">
                  </el-option>
                  <el-option label="每月一次" :value="3">
                  </el-option>
                  <el-option label="每三个月一次" :value="4">
                  </el-option>
                  <el-option label="每六个月一次" :value="5">
                  </el-option>
                  <el-option label="每一年一次" :value="6">
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:12px">
                <span class="labelName left">组织机构</span>
                <!-- <el-select size="small" v-model="form.departmentIdList" placeholder="请选择所属组织机构" @change="changePlan">
                  <el-option :label="item.name" :value="item.id" v-for="item in options" :key="item.id">
                  </el-option>
                </el-select> -->
                <!-- checkStrictly: true,   -->
                <el-cascader @change="changePlan" :options="options" placeholder="请选择所属组织机构" :show-all-levels="false"
                  :props="{ value: 'id', label: 'name', emitPath: false, multiple: true, }"
                  v-model="form.departmentIdList"></el-cascader>
              </div>
              <div style="margin-top:12px">
                <span class="labelName left">参与测评</span>
                <el-select size="small" v-model="form.type" placeholder="请选择参与测评人员" @change="changePlan">
                  <!-- <el-option label="所有人可参与" :value="1" v-if="settingInfo.tourist === 1">
                  </el-option> -->
                  <el-option label="用户可参与" :value="2">
                  </el-option>
                  <el-option label="指定推送" :value="3">
                  </el-option>
                  <el-option label="仅扫码可参与" :value="4">
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:12px">
                <span class="labelName left">注册用户所属</span>
                <el-cascader @change="changePlan" :options="options" placeholder="通过该测评计划新注册用户所属组织机构"
                  :show-all-levels="false" :props="{ value: 'id', label: 'name', emitPath: false }"
                  v-model="form.departmentId"></el-cascader>
                <!-- <el-select size="small" v-model="form.departmentId" placeholder="通过该测评计划新注册用户所属组织机构" @change="changePlan"></el-select> -->
              </div>
              <div style="margin-top:12px" v-if="settingInfo.payment === 'true' || settingInfo.payment === true">
                <span class="labelName left">付费类型</span>
                <el-select size="small" v-model="form.priceType" placeholder="请选择付费类型" @change="changePlan">
                  <el-option label="免费" :value="0">
                  </el-option>
                  <el-option label="付费" :value="1">
                  </el-option>
                </el-select>
              </div>
              <div style="margin-top:12px" v-if="form.priceType === 1 && settingInfo.payment === true">
                <span class="labelName left">原价</span>
                <el-input v-model="form.originalPrice" clearable @blur="changePlan" type="number"></el-input>
              </div>
              <div style="margin-top:12px" v-if="form.priceType === 1 && settingInfo.payment === true">
                <span class="labelName left">折扣价</span>
                <el-input v-model="form.discountPrice" clearable @blur="changePlan" type="number"></el-input>
              </div>
              <div style="margin-top:12px">
                <span class="labelName left">计划说明</span>
                <el-input style="width: 380px" v-model="form.memo" clearable @blur="changePlan" type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
              </div>
              <div>
                <span class="labelName left">合并量表</span>
                <el-switch v-model="form.isMerge" :active-value="1" :inactive-value="0" @change="changePlan">
                </el-switch>
              </div>
              <div>
                <span class="labelName left">启用</span>
                <el-switch v-model="form.status" :active-value="1" :inactive-value="0" @change="changePlan">
                </el-switch>
              </div>
            </div>
            <div class="right">
              <span class="labelName">测评计划二维码</span>
              <div class="image" id="imgs">
                <!-- <img src="../../../../assets/img.png" alt="" style="height:100px;width:100px;"> -->
                <div class="qrcode" ref="qrCodeUrl" id="qrcode"></div>
                <el-button type="primary" style="margin-left: 20px;" size="small" @click="printOut">下载</el-button>
              </div>
              <!-- <div class="qrcode" ref="qrCodeUrl"></div> -->
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn">
      <el-button type="primary" size="small" @click="addPlan">新增</el-button>
      <el-button type="primary" size="small" @click="deleteGauges">删除</el-button>
    </div>
    <div class="tableList">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column prop="gaugeName" label="量表名称" width="300" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="gaugeShowName" label="量表显示名称" width="300" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="problemCount" label="题目数量">
        </el-table-column>
        <el-table-column prop="isReport" label="开放报告">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isReport" :active-value="1" @change="changeReport(scope.row)"
              :inactive-value="0">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isRandom" label="随机乱序">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isRandom" :active-value="1" @change="changeRandom(scope.row)"
              :inactive-value="0">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isRepeat" label="多次测评">
          <template slot-scope="scope">
            <el-select v-model="scope.row.isRepeat" class="changeRepeat" @change="changeRepeat(scope.row)">
              <el-option label="一次" :value="0">
              </el-option>
              <el-option label="多次" :value="1">
              </el-option>
            </el-select>
            <!-- <span v-if="scope.row.isRepeat === 1"> 多次</span>
            <span v-else>一次</span> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AddGaugeVue ref="add" @getDetail="getDetail"></AddGaugeVue>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import AddGaugeVue from './AddGauge.vue';
import { getPlanDetail, deleteGauge, updateGauges, updatePlanEvaluation } from '@/api/evaluationManagement/plan'
import { getOrganizationList } from '@/api/system/organization'
import QRCode from 'qrcodejs2'
import { getSetting } from '@/api/settings'
export default {
  data() {
    return {
      form: {
        // userId: null,
        startTime: '', //开始时间
        endTime: '', // 结束时间
        name: '', //名称
        repeatPlan: null, // 计划周期
        isMerge: null, //是否合并
        status: null, //状态 是否启用
        type: 2,
        repeatEndTime: null,
        repeatStartTime: null,
        departmentIdList: [], // 测评计划所属组织机构
        departmentId: null, // 新建用户所属组织机构
        priceType: 0, // 付费类型
        originalPrice: null, // 原价
        discountPrice: null, //折扣价
        memo: null, // 计划说明

      },
      isCode: 0, // 参与计划类型是否为仅扫码可参与 1是 0否
      settingInfo: {},
      rules: {
        name: [
          { max: 20, message: '长度在 20 个字符内', trigger: 'change' }
        ]
      },
      deleteGaugeIdList: [],
      options: null, // 组织机构数据
      planId: null, //计划id
      tableData: [], // 表格数据
      val: [],
      isRegister: 0, //是否允许注册，如果没有选择注册组织机构所属，则应该不能注册

    }
  },
  methods: {
    // 点击新增
    addPlan() {
      // console.log('add')
      this.$refs.add.openAdd();
    },
    // 获取组织机构列表
    async getAllList() {
      const res = await getOrganizationList()
      console.log(res);
      if (res.code === 200) {
        this.options = res.data;
        // this.options.push({ id: 0, name: '无(第一级)' })
        // this.deleteNullChildren(this.options)
      }
    },
    // 获取配置信息
    async getLogs() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    },
    // 获取计划详情
    async getDetail() {
      if (sessionStorage.getItem('planId') !== 'null') {
        const res = await getPlanDetail({ id: Number(sessionStorage.getItem('planId')) });
        console.log(res);
        if (res.code === 200) {
          sessionStorage.setItem('planType', res.data.type);
          this.form = res.data;
          this.tableData = res.data.planGaugeRefs;
          this.val = [];
          if (res.data.startTime !== null) {
            this.val.push(res.data.startTime);
          }
          if (res.data.endTime !== null) {
            this.val.push(res.data.endTime);
          }
          // 判断是否为扫码可参与
          if (this.form.type === 4) {
            this.isCode = 1
          } else {
            this.isCode = 0
          }

          // 判断是否选择了注册用户所属
          if(this.form.departmentId !== null) {
            this.isRegister = 1;
          }
          this.creatQrCode(res.data.id);
          // console.log(this.val)

        }
      }

    },
    // 是否开放报告
    async changeReport(val) {
      console.log(val)
      // updateGauges
      // if (this.form.isMerge === 1) {
      //   console.log('合并');
      //   this.$confirm('合并量表状态下修改此状态，其他量表也会更改。是否继续?', '注意', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(async () => {
      //     const res = await updateGauges(JSON.stringify({ isReport: val.isReport, id: val.id, planId: this.planId }));
      //     console.log(res);
      //     if (res.code === 200) {
      //       this.$message({
      //         showClose: true,
      //         message: '操作成功！',
      //         type: 'success'
      //       });
      //       this.getDetail();
      //     } else {
      //       this.$message({
      //         showClose: true,
      //         message: '操作失败！',
      //         type: 'error'
      //       });
      //     }
      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消删除'
      //     });
      //   });
      // } else {
      //   console.log('未合并');

      //   const res = await updateGauges(JSON.stringify({ isReport: val.isReport, id: val.id, planId: this.planId }));
      //   console.log(res);
      //   if (res.code === 200) {
      //     this.$message({
      //       showClose: true,
      //       message: '操作成功！',
      //       type: 'success'
      //     });
      //     this.getDetail();
      //   } else {
      //     this.$message({
      //       showClose: true,
      //       message: '操作失败！',
      //       type: 'error'
      //     });
      //   }
      // }
      const res = await updateGauges(JSON.stringify({ isReport: val.isReport, id: val.id, planId: Number(sessionStorage.getItem('planId')) }));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '操作成功！',
          type: 'success'
        });
        this.getDetail();
      } else {
        this.$message({
          showClose: true,
          message: '操作失败！',
          type: 'error'
        });
      }

    },
    // 是否随机乱序
    async changeRandom(val) {
      console.log(val)
      console.log(this.planId)
      if (this.form.isMerge === 1) {
        console.log('合并');
        this.$confirm('合并量表状态下修改此状态，其他量表也会更改。是否继续?', '注意', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {

          const res = await updateGauges(JSON.stringify({ isRandom: val.isRandom, id: val.id, planId: this.planId }));
          console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              message: '操作成功！',
              type: 'success'
            });
            this.getDetail();
          } else {
            this.$message({
              showClose: true,
              message: '操作失败！',
              type: 'error'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        console.log('未合并');
        const res = await updateGauges(JSON.stringify({ isRandom: val.isRandom, id: val.id, planId: this.planId }));
        console.log(res);
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '操作成功！',
            type: 'success'
          });
          this.getDetail();
        } else {
          this.$message({
            showClose: true,
            message: '操作失败！',
            type: 'error'
          });
        }
      }


    },
    async changeRepeat(val) {
      const res = await updateGauges(JSON.stringify({ isRepeat: val.isRepeat, id: val.id, planId: this.planId }));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '操作成功！',
          type: 'success'
        });
        this.getDetail();
      } else {
        this.$message({
          showClose: true,
          message: '操作失败！',
          type: 'error'
        });
      }
    },
    // 选择量表
    handleSelectionChange(val) {
      console.log(val)
      this.deleteGaugeIdList = [];
      if (val) {
        val.forEach(item => {
          this.deleteGaugeIdList.push(item.id)
        })
      }
    },
    // 删除量表
    async deleteGauges() {
      console.log(this.deleteGaugeIdList);
      if (this.deleteGaugeIdList.length > 0) {
        this.$confirm('确认删除该量表?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await deleteGauge(JSON.stringify(this.deleteGaugeIdList));
          console.log(res);
          if (res.code === 200) {
            this.$message({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            });
            this.getDetail();
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: '删除失败!'
            });
          }
        }).catch(() => {
          this.$message({
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要删除的量表！'
        });
      }
    },
    // getTime() {
    //   console.log(this.val)
    // },
    // 修改是否合并
    async changeIsMerge() {
      console.log(this.form);
      const res = await updatePlanEvaluation({ id: this.planId, isMerge: this.form.isMerge });
      console.log(res);

    },
    // 修改量表信息
    async changePlan() {
      console.log(this.form.departmentIdList)
      
      if (this.form.name.length < 21) {
        if (sessionStorage.getItem('planId') !== null) {
          this.form.id = Number(sessionStorage.getItem('planId'));
        }
        this.form.repeatEndTime = this.form.endTime;
        this.form.repeatStartTime = this.form.startTime;
        // this.form.userId = JSON.parse(sessionStorage.getItem('userInfo')).id;
        let info = JSON.parse(JSON.stringify(this.form));
        delete info.userName;
        delete info.planGaugeRefs;
        console.log(this.form);
        const res = await updatePlanEvaluation(JSON.stringify(info));
        console.log(res);
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          });

          this.planId = res.data;
          // this.creatQrCode(res.data);
          // this.$nextTick(()=>  {
          //   this.creatQrCode(res.data);
          // });
          sessionStorage.setItem('planId', res.data)
          this.getDetail();
        } else {
          this.$message({
            showClose: true,
            message: '修改失败！' + res.msg,
            type: 'error'
          });
          this.getDetail();
        }
      } else {
        this.$message({
          showClose: true,
          message: '修改失败,测评计划名称长度为20！'
        });
      }
    },
    // 动态生成二维码
    creatQrCode(val) {
      // console.log(document.getElementById('imgs').firstElementChild.childNodes);
      // let element = document.getElementById('imgs');
      // element.parentNode.removeNode(element);
      var el = document.getElementById('qrcode');
      var childs = el.childNodes;
      for (var i = childs.length - 1; i >= 0; i--) {
        el.removeChild(childs[i]);
      }
      
      // if(document.getElementById('imgs').firstElementChild.childNodes.length > 2) {

      // }
      this.$nextTick(() => {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: `${this.$store.state.downLoadUrl}?id=${val}&isScan=${this.isCode}&isRg=${this.isRegister}`, // 需要转换为二维码的内容

          // text: `${this.$store.state.downLoadUrl}?id=${val}`, // 需要转换为二维码的内容
          width: 120,
          height: 120,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      });
      // console.log(`${this.$store.state.downLoadUrl}?id=${val}&isScan=${this.isCode}`)
    },
    // 实现导出页面为图片
    // ---------------分割线-------------------
    dataURLToBlob(dataurl) {//ie 图片转格式
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime })
    },
    downloadResult(name) {
      let canvasID = document.getElementById('qrcode');
      let a = document.createElement('a');
      html2canvas(canvasID).then(canvas => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = "none";
        a.style.display = "none";
        document.body.removeChild(dom);
        let blob = this.dataURLToBlob(dom.toDataURL("image/png"));
        a.setAttribute("href", URL.createObjectURL(blob));
        a.setAttribute("download", name + ".png")
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blob);
        document.body.removeChild(a);
      });
    },
    // 点击导出
    printOut() {
      let name = `二维码`
      // 个人观察只是截取可见范围以及以下的区域，所以先将滚动条置顶
      // $(window).scrollTop(0); // jQuery 的方法
      document.body.scrollTop = 0; // IE的
      document.documentElement.scrollTop = 0; // 其他
      this.downloadResult(name)
    }
    // ---------------分割线-------------------
  },
  created() {

    this.planId = sessionStorage.getItem('planId');
    this.getDetail();
    this.getAllList();
    this.getLogs();
    sessionStorage.setItem('planType', 1);
    console.log(this.form)

  },
  mounted() {
    if (this.planId !== null) {
      this.creatQrCode(this.planId);
    } else {
      this.creatQrCode();
    }


  },
  components: { AddGaugeVue }

}
</script>

<style lang="scss" scoped>
.Overview {
  .tableForm {

    .el-input__inner,
    .el-input,
    .el-select,
    .el-cascader {
      width: 380px;
      // display: inline-block;
    }

    .time {
      display: flex;
      width: 380px;

      span {
        display: inline-block;
        margin: 0 10px;
      }
    }

    .createInfo,
    .image {
      width: 380px;
      display: inline-block;
    }

    .first {
      display: flex;
      width: 100%;

      .left,
      .right {
        width: 50%;
        display: flex;
      }

      .left1 {
        width: 50%;
      }

      .labelName {
        width: 120px;
        display: inline-block;
        text-align: right;
        margin-right: 10px;
      }

      .labelName.left {
        width: 85px;
      }
    }
  }

  .tableList {
    margin-top: 10px;
  }

  .changeRepeat {
    box-sizing: border-box;
    height: 32px !important;
    line-height: 32px;
    border: none;

    ::v-deep.el-input__icon {
      line-height: 32px !important;
    }

    ::v-deep.el-input__inner {
      height: 32px !important;
      line-height: 32px;
    }
  }

  .qrcode {
    display: inline-block;

    img {
      width: 140px;
      height: 140px;
      background-color: #fff; //设置白色背景色
      padding: 5px; // 利用padding的特性，挤出白边
      box-sizing: border-box;
    }
  }

  // padding: 20px 0px;
}

// // 取消数字输入框后的上下减
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>